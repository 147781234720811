<template>
  <div class="adminInfo_container">
    <div class="adminInfo_banner">
      <div class="adminInfo_info">
        <div class="adminInfo_info_left">
          <img
            class="adminInfo_avatar"
            :src="require('@/assets/img/avatar.png')"
          />
        </div>
        <div class="adminInfo_info_right">
          <div class="adminInfo_info_right_top">
            <div>
              <div class="name">{{ data.name }}/{{ data.name_pinyin }}</div>
              <div class="department">
                {{ data.department }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>管理员编号：{{ data.sale_phone }}</span>
              </div>
            </div>

            <!--  -->

            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: end;
              "
            >
              <CustomButton class="CustomButton" @click="goToPersonalInfo"
                >完善个人信息</CustomButton
              >
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="data.work_card_ocr_status === 0"
              >
                专家职称信息审核中
              </div>
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="data.work_card_ocr_status === 2"
              >
                专家职称信息失败
              </div>
            </div>
          </div>
          <div class="adminInfo_info_right_bottom">
            <div class="hospital">
              <img :src="require('@/assets/img/hospital.png')" />{{
                data.hospital
              }}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="province_city">
              <img :src="require('@/assets/img/location.png')" />{{
                data.province
              }}&nbsp;&nbsp;&nbsp;&nbsp;{{ data.city }}
            </div>
          </div>
        </div>
      </div>
      <div class="adminInfo_meetInfo">
        <span>
          总会议数：
          <span class="global-color"> {{ data.all_count }}</span></span
        >
        <span>
          已预约：
          <span class="global-color"> {{ data.booked_count }}</span></span
        >
        <span>
          已召开：
          <span class="global-color"> {{ data.convened_count }}</span></span
        >
      </div>
      <div
        style="
          display: flex;
          justify-content: end;
          margin-top: 10px;
          padding-right: 16px;
        "
      >
        <CustomButton class="CustomButton" @click="handlerMeetCount"
          >调整参会次数</CustomButton
        >
      </div>
    </div>

    <div class="adminInfo_main">
      <div class="title">会议列表</div>
      <div class="info_tips" v-if="data?.meet_list?.length == 0">暂无数据</div>
      <ItemScroll @pullingUp="pullingUp" v-else ref="ItemScroll">
        <div class="adminInfo_main_block">
          <meetItem
            v-for="(item, index) in data.meet_list"
            :key="index"
            :item="item"
            :type="1"
            @goToMeet="goToMeet"
            >{{ index }}</meetItem
          >
        </div>
      </ItemScroll>
    </div>
    <div class="adminInfo_foot">
      <CustomButton
        class="CustomButton"
        @click="createMeet"
        v-if="data.all_count - data.booked_count != 0"
        >新建会议</CustomButton
      >
      <CustomButton class="CustomButton" @click="goBack"
        >返回上一页</CustomButton
      >
    </div>
    <popup :msg="popMsg" v-if="showPopup" @doClose="doClose" @doSure="doSure">
      <div class="powerpopup_main">
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">总会议数：</div>
          <div class="powerpopup_row_value">
            <inputNum
              v-model="data.all_count"
              :bookedCount="data.booked_count"
            ></inputNum>
          </div>
        </div>
        <div class="powerpopup_tips">注：总会议次数不得小于已预约会议次数</div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">已预约：</div>
          <div class="powerpopup_row_value">
            {{ data.booked_count }}
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">已召开：</div>
          <div class="powerpopup_row_value">
            {{ data.convened_count }}
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import meetItem from "./meetItem.vue";
import ItemScroll from "../../unit/ItemScroll.vue";
import Popup from "../../unit/Popup.vue";
import inputNum from "@/components/unit/inputNum.vue";
import weixin from "@/common/weixin";
export default {
  name: "adminInfo",
  components: { CustomButton, meetItem, ItemScroll, Popup, inputNum },
  data() {
    return {
      meet_count: 0,
      popMsg: {
        align: "center",
        title: "调整参会次数",
        btngroup: { sure: "确认调整", close: "返回" },
      },
      showPopup: false,
      data: {},
      dataList: [
        {
          title: "细胞协奏：揭秘骨转移癌症中的免疫密码",
          meet_date: "2024-03-20 19:00",
          status: 0,
        },
        {
          title: "细胞协奏：揭秘骨转移癌症中的免疫密码",
          meet_date: "2024-03-20 19:00",
          status: 1,
        },
      ],
      search: {
        page: "",
        pagesize: "20",
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      //salesUserHome
      let url = this.$tools.getURL(this.$urls.sale.salesUserHome, {
        id: this.$route.query.id,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.ItemScroll.initScroll();
            }, 1000);
          });
        })
        .catch(() => {});

      // this.$axios.get(this)
    },
    pullingUp() {
      this.page += 1;
      this.loadData();
    },
    handlerMeetCount() {
      this.meet_count = this.data.all_count;
      this.showPopup = true;
    },
    doSure() {
      if (this.data.all_count < this.data.booked_count)
        return this.$tips.error({ text: "总会议次数不得小于已预约会议次数" });
      let loading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.sale.patchUserCount, {
          id: this.data.id,
          meet_count: this.data.all_count,
        })
        .then(() => {
          loading.close();
          this.showPopup = false;
        })
        .catch(() => {
          setTimeout(() => {
            loading.close();
            this.showPopup = false;
            this.data.all_count = this.meet_count;
          }, 1000);
        });
    },
    doClose() {
      this.data.all_count = this.meet_count;
      this.showPopup = false;
    },
    createMeet() {
      // if (this.data.work_card_ocr_status === 0) {
      //   return this.$tips.error({
      //     text: "专家职称信息审核中，审核通过后方可创建会议",
      //   });
      // }
      // if (this.data.work_card_ocr_status === 2) {
      //   return this.$tips.error({
      //     text: "专家职称信息审核失败，审核通过后方可创建会议",
      //   });
      // }
      // if (!this.data.first_complete)
      //   return this.$tips.error({ text: "完善个人信息后，方可创建会议" });
      this.$tools.setStorage("coursewareData", {});
      this.$tools.setStorage("meetData", {});
      this.$router.push({
        path: "/meetInfo",
        query: {
          user_id: this.$route.query.id,
          form: "/adminInfo",
        },
      });
    },
    goToMeet(item) {
      if (item.analysis_status == 0)
        return this.$tips.success({ text: "课件解析中，请稍后" });
      return this.$tips.success({ text: "观看会议" });

      let content = {
        projectId: this.$config.projectId,
        openid: item.openid,
        meetId: item.id,
      };
      let url = this.$tools.getURL("/pages/user/bind", content);
      weixin.wx.miniProgram.redirectTo({ url });
    },
    goToPersonalInfo() {
      this.$router.push({
        path: "/personalInfo",
        query: {
          id: this.data.id,
          from: this.$route.path,
        },
      });
    },
    goBack() {
      this.$router.push({
        path: "/adminMeet",
      });
    },
  },
};
</script>

<style >
.adminInfo_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.adminInfo_banner {
  background-image: url("../../../assets/img/banner.png");
  background-size: 100% 100%;
  height: 248px;
  width: 100%;
}
.adminInfo_info {
  margin: 16px;
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #756767;
}
.adminInfo_avatar {
  width: 54px;
  height: 54px;
}

.adminInfo_info_right {
  flex: 1 0 0;
  margin-left: 12px;
  color: #fff;
}
.adminInfo_info_right_top {
  height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.adminInfo_info_right_top .name {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
}
.adminInfo_info_right_top .department {
  font-size: 12px;
}
.adminInfo_banner .CustomButton {
  width: 100px;
  height: 20px;
  font-size: 14px;
  padding: 0;
  line-height: 1;
}
.adminInfo_info_right_bottom {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
}
.adminInfo_info_right_bottom img {
  width: 16px;
  margin-right: 4px;
}
.adminInfo_meetInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #ffffff;
  padding: 0 38px;
}
.adminInfo_foot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;
}
.adminInfo_foot .CustomButton {
  width: 100px;
  height: 30px;
  font-size: 14px;
  line-height: 1;
}
.adminInfo_foot .CustomButton:last-child {
  margin-top: 15px;
}

.adminInfo_main {
  margin: 0 16px;
  margin-top: -60px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  flex: 1 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.adminInfo_main .title {
  font-weight: bold;
  font-size: 14px;
  color: #009d7d;
  margin-bottom: 10px;
}
.meetItem_container:last-child {
  border: none;
}

.adminInfo_container .powerpopup_row {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #606060;
}
.adminInfo_container .powerpopup_tips {
  font-size: 12px;
  color: #009d7d;
  line-height: 26px;
}
.adminInfo_container .popup_btn_group {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 20px;
}

.adminInfo_container .popup_btn_group .customButton_container {
  width: 104px;
  height: 31px;
  margin-bottom: 16px;
}
.info_tips {
  color: #ccc;
  font-size: 14px;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>